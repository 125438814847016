import Phaser from 'phaser'

export class TextButton {
  constructor(scene, x, y, width, height, text, font, icon, callback, color) {
    //super(scene);
    if(!color) {
      color = {
        background: 0xFC6801,
        hover: 0xFFAD02,
      }
    }
    var box = scene.add.graphics();
    box.fillStyle(color.background, 1).fillRoundedRect(x, y, width, height, height/2);
    
    if(!font)font={ fontFamily: "WF Prompt", fontSize: 50, color: '#ffffff', fontWeight: 'bold' }
    this.obtext = scene.add.text(0, 0,  text, font).setOrigin(0.5)

    if(icon) {
      scene.add.image(x+width - 55, y+height -50, icon)
      this.obtext.x = x+((width)/2)-30
    } else {
      this.obtext.x = x+((width)/2)
    }
    this.obtext.y = y+((height)/2)

    if(callback) {
      var rect = new Phaser.Geom.Rectangle( x, y, width, height)
      box.setInteractive({hitarea:rect,useHandCursor:true,hitAreaCallback:(hitArea,x,y,obj)=>{return Phaser.Geom.Rectangle.Contains(rect,x,y)}} )
        .on('pointerdown',()=>{callback();})
        .on('pointerover', () => {
          box.clear().fillStyle(color.hover, 1).fillRoundedRect(x, y, width, height, height/2);
        })
        .on('pointerout', ()=>{
          box.clear().fillStyle(color.background, 1).fillRoundedRect(x, y, width, height, height/2);
        });
    }
  }

  setText(text) {
    this.obtext.setText(text)
  }
}