import { Scene } from 'phaser'

export default class BaseScene extends Scene {
  constructor(key) {
    super(key)
  }

  loadingProgress() {
    var progressBar = this.add.graphics();
    var progressBox = this.add.graphics();
    progressBox.fillStyle(0x222222, 0.8);
    progressBox.fillRoundedRect(this.cameras.main.centerX-(300/2),this.cameras.main.centerY-(40/2), 320, 40, 10);

    var width = this.cameras.main.width;
    var height = this.cameras.main.height;
    var loadingText = this.make.text({
      x: this.cameras.main.centerX,
      y: this.cameras.main.centerY-80,
      text: 'กำลังดาวน์โหลด...',
      style: { fontFamily: "WF Prompt", fontSize: 50, color: '#FC6801', fontWeight: 'bold' }
    });
    loadingText.setOrigin(0.5, 0.5);

    var percentText = this.make.text({
      x: this.cameras.main.centerX,
      y: this.cameras.main.centerY,
      text: '0%',
      style: { fontFamily: "WF Prompt", fontSize: 30, color: '#FFC64D', fontWeight: 'bold' }
    });
    percentText.setOrigin(0.5, 0.5);

    var assetText = this.make.text({
      x: width / 2,
      y: height / 2 + 50,
      text: '',
      style: {
        font: '18px monospace',
        fill: '#000000'
      }
    });
    assetText.setOrigin(0.5, 0.5);

    this.load.on('progress',  (value)=> {
      percentText.setText(parseInt(value * 100) + '%');
      progressBar.clear();
      progressBar.fillStyle(0xffffff, 1);
      progressBar.fillRoundedRect(this.cameras.main.centerX-(300/2),this.cameras.main.centerY-(40/2), (300 * value)+20, 40, 10);
    });

    this.load.on('fileprogress', function (file) {
      assetText.setText('Loading asset: ' + file.key);
    });

    this.load.on('complete', function () {
      progressBar.destroy();
      progressBox.destroy();
      loadingText.destroy();
      percentText.destroy();
      assetText.destroy();
    });
  }
}
